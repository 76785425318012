<template>
  <v-card>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-forward</v-icon>
      </template>
    </v-breadcrumbs>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-form @submit.prevent="createSystem">
        <v-card>
          <v-card-title>
            <span class="headline">{{ systemObj.name || 'System' }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="System name*"
                    :error-messages="nameErrors"
                    v-model="systemObj.name"
                    required
                    @blur="$v.systemObj.name.$touch()"
                    >{{ systemObj.name }}</v-text-field
                  >
                </v-col>
                <!-- <v-col cols="12" sm="12" md="6">
                  <v-select
                    :items="SYSTEM_NAMES_ENUM"
                    :error-messages="systemTypeErrors"
                    v-model="systemObj.system_type"
                    label="System type"
                    item-text="name"
                    item-value="code"
                    persistent-hint
                    return-object
                    single-line
                    required
                  ></v-select>
                </v-col> -->
                <v-col cols="12">
                  <v-text-field
                    label="System URL"
                    :error-messages="urlErrors"
                    v-model="systemObj.url"
                    required
                    >{{ systemObj.url }}</v-text-field
                  >
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="System Operation URL"
                    :error-messages="operatioUrlErrors"
                    v-model="systemObj.operation_url"
                    required
                    >{{ systemObj.operation_url }}</v-text-field
                  >
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    label="Smart code*"
                    v-model="systemObj.smart_codes"
                    name="smart_codes"
                    :filter="filter"
                    :items="systemObj.smart_codes"
                    :error-messages="systemCodesErrors"
                    hide-selected
                    multiple
                    small-chips
                    solo
                    required
                  >
                  </v-combobox>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :disabled="$v.anyError"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <div class="wrapper float-right">
      <v-btn
        v-if="isAdmin"
        small
        dark
        @click="checkClientSettings(system)"
        color="info lighten-1"
        ><v-icon>
          mdi-table-key
        </v-icon>client settings</v-btn
      >
      <v-btn
        v-if="isAdmin"
        small
        color="cyan"
        dark
        left
        @click="getSystem(system.systemId)"
        ><v-icon color="white">mdi-pencil-box-outline</v-icon>change
        system</v-btn
      >
      
      <v-btn
        v-if="isAdmin"
        small
        color="info"
        right
        :href="system.systemUrl"
        target="_blank"
        ><v-icon color="orange">mdi-open-in-new</v-icon>to integration
        admin</v-btn
      >
      <v-btn
        color="green darken-4 mr-2 float-right"
        small
        dark
        @click="runSync(system.systemId)"
        ><v-icon>mdi-database-sync </v-icon>
        sync
      </v-btn>
    </div>
    <h4 class="text-center mt-10 mb-10">{{ system.systemName }}</h4>

    <v-container class="graph">
      <v-row>
        <v-col>
          <span class="span text-center">Dates</span>
          <apexcharts
            width="500"
            type="bar"
            class="chart"
            sm="3"
            :options="chartOptions"
            :series="series"
          ></apexcharts>
        </v-col>
        <v-col>
          <span class="span text-center">Interations</span>
          <apexcharts
            width="350"
            type="donut"
            class="md-6 chart donut"
            :options="donutchartOptions"
            sm="3"
            :series="donutseries"
          ></apexcharts>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialogClientSettings"
      scrollable
      max-width="524px"
      max-higth="800px"
    >
      <v-card>
        <v-card-title
          >Client settings for {{ clientSettings.name }}</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text style="height: 100px;">
          <p>
            System_id: <b>{{ clientSettings._id }}</b>
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text style="height: 120px;">
          <p>
            Token: <b>{{ clientSettings.token }}</b>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogClientSettings = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import ErrorService from '@/services/ErrorService.js'
import SystemService from '@/services/SystemService.js'
import IntegrationService from '@/services/IntegrationService.js'
import BreadcrumbsManager from '@/mixins/BreadcrumbManager.js'
import { SystemMixin } from '@/mixins/SystemMixin.js'

export default {
  mixins: [SystemMixin, BreadcrumbsManager],
  components: {
    apexcharts: VueApexCharts
  },
  data: () => {
    return {
      chartOptions: {},
      donutchartOptions: {},
      series: [],
      donutseries: [],
      dialogClientSettings: false,
      clientSettings: {},
    }
  },
  methods: {
    checkClientSettings(item) {
      SystemService.getSystem(item.systemId).then(response => {
        this.dialogClientSettings = true
        this.clientSettings = response.data
      })
      
    },
    runSync(systemId) {
      SystemService.getSystem(systemId).then(response => {
        IntegrationService.runSync(systemId, response.data.smart_codes)
          .then(() => {
            const notification = {
              type: 'success',
              message: `Synchronisation for ${response.data.smart_codes} finished`
            }
            this.$store.dispatch('notification/add', notification, {
              root: true
            })
          })
          .catch(error => {
            console.log(error.response)
            this.$store.dispatch('setLoader', false)
          })
      })
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Home', to: { path: '/' } },
      {
        text: ':systems'
      },
      { text: ':system' } // placeholder
    ])
    this.replaceBreadcrumb({
      find: ':system',
      replace: {
        text: `${this.system.systemName}`,
        to: {
          name: 'systemErrors',
          params: { system_id: this.system.systemId }
        },
        disabled: true
      }
    })
    this.replaceBreadcrumb({
      find: ':systems',
      replace: {
        text: this.system.systemTypeName,
        to: {
          name: 'systemsByType',
          params: {
            system_type: this.system.systemType,
            TypeName: this.system.systemTypeName
          }
        }
      }
    })
    getAggregatedErrorsByDates: {
      ErrorService.getAggregateErrorsBySytemIdDates(
        this.$route.params.system_id
      ).then(response => {
        this.chartOptions = {
          chart: {
            id: 'data'
          },
          xaxis: { categories: response.data.dates }
        }
        this.series = [{ name: 'errors count', data: response.data.counts }]
      })
    }
    getAggregatedErrorsByIntegrationjs: {
      ErrorService.getAggregateErrorsBySytemIdIntgrations(
        this.$route.params.system_id
      ).then(response => {
        this.donutchartOptions = {
          labels: response.data.integrations
        }
        this.donutseries = response.data.counts
      })
    }
  },
  computed: {
    system() {
      return this.$store.state['systems']['system']
    },
    breadcrumbs() {
      return this.$store.state.breadcrumbs.breadcrumbs
    },
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
    nameErrors() {
      const errors = []
      if (!this.$v.systemObj.name.$dirty) return errors
      !this.$v.systemObj.name.required && errors.push('Name is required.')
      return errors
    },
    urlErrors() {
      const errors = []
      if (!this.$v.systemObj.url.$dirty) return errors
      !this.$v.systemObj.url.required && errors.push('URl is required.')
      !this.$v.systemObj.url.url && errors.push('invalid url')
      return errors
    },
    operatioUrlErrors() {
      const errors = []
      if (!this.$v.systemObj.operation_url.$dirty) return errors
      !this.$v.systemObj.operation_url.url && errors.push('invalid url')
      return errors
    },
    // systemTypeErrors() {
    //   const errors = []
    //   if (!this.$v.systemObj.system_type.$dirty) return errors
    //   !this.$v.systemObj.system_type.required &&
    //     errors.push('System Type is required.')
    //   return errors
    // },
    systemCodesErrors() {
      const errors = []
      if (!this.$v.systemObj.system_codes.$dirty) return errors
      console.log(this.systemObj.smart_codes.length)
      if (this.systemObj.smart_codes.length === 0) {
        errors.push('System codes is required')
      }
      return errors
    }
  }
}
</script>
<style scoped>
.chart {
  max-width: 500px !important;
  margin: 0 40px;
  display: inline-block;
}
.span {
  text-align: center;
  display: block;
}
.donut {
  margin-top: 25px;
}
.wrapper {
  margin-left: 20px;
}
</style>
